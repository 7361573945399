/* ==========================================================================
   Browser Upgrade Prompt
   ========================================================================== */

.browserupgrade {
    margin: 0.2em 0;
    background: $gray-100;
    color: $body-color;
    text-align: center;
    padding: 0.2em 0;
    width: 100%;
    position: fixed;
	top: 0;
    left: 0;
    z-index: 9999;

    a {
    	color: $red;
    }
}

/* ==========================================================================
   Bernabé's custom styles
   ========================================================================== */

body {
    &.navbar-fixed-active {
        padding-top: 194px;
    }
}

@include media-breakpoint-down('sm') {

    h3 {
        font-size: 2.5rem;
    }
}

.navbar {
    padding-top: 1rem;
    padding-bottom: 2rem;
    background-color: $white;
    position: static;
    top: -92px;
    transition: top .8s ease;

    h1 {
        font-size: 0;
        margin: 0;
    }

    .navbar-brand {
        .iso {
            display: none;
        }
    }

   .navbar-nav {
        .nav-item {
            + .nav-item {
                margin-left: 1rem;
            }
        }
        .nav-link {
            &.active {
                &:after {
                  background-color: $yellow;
                  width: 100%;
                }
            }
        }
    }

    .navbar-toggler {
        width: 2rem;
        cursor: pointer;

        .icon-bar {
            display: block;
            width: 100%;
            height: 2px;
            background-color: $navbar-light-toggler-icon-bg;
            border-radius: 1px;
            float: right;
            transition: all .4s ease;

            &:first-child {
                width: 80%;
            }

            &:nth-child(3) {
                width: 60%;
            }
        }
        .icon-bar + .icon-bar {
            margin-top: 8px;
        }

        &:hover {
            
            .icon-bar {
                &:first-child,
                &:nth-child(3) {
                    width: 100%;
                }
            }
        }

        &.in {

            .icon-bar {

                &:nth-child(2) {
                    width: 1px;
                    opacity: 0;
                }

                &:first-child,
                &:nth-child(3) {
                    width: 100%;
                }

                &:first-child {
                    transform: rotate(-45deg) translate(-7px, 7px);
                }

                &:nth-child(3) {
                    transform: rotate(45deg) translate(-7px, -7px);
                }
                
            }
        }
    }

    &.navbar-fixed {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: $zindex-fixed;
        padding-bottom: 1rem;
        box-shadow: 0 0 12px -4px rgba($black, .4);

        .navbar-brand {
            .logo {
                display: none;
            }
            .iso {
                display: block;
            }
        }
    }
}

.navbar-sweeping,
.navbar-offcanvas {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: calc(100% - 4rem);
    height: 100vh;
    transform: translateX(-100%);
    transition: transform .8s ease;
}

.navbar-sweeping {
    z-index: ($zindex-fixed + 10);
    background-color: rgba($yellow, .7);
}

.navbar-offcanvas {
    z-index: ($zindex-fixed + 20);
    background-color: rgba($blue-dark, .94);
    transition-delay: .2s;

    .content {
        padding: 1rem;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                transform: translateY(700%);
                opacity: 0;
                transition: transform .8s ease, opacity .8s ease;

                &:first-child {
                    transition-delay: .8s;
                }

                &:nth-child(2) {
                    transition-delay: 1s;
                }

                &:nth-child(3) {
                    transition-delay: 1.2s;
                }

                &:nth-child(4) {
                    transition-delay: 1.4s;
                }

                &:nth-child(5) {
                    transition-delay: 1.6s;
                }
            }
        }

        .nav-link {
            color: $white;
            font-weight: $font-weight-semi;
            text-transform: uppercase;
            padding-right: $navbar-nav-link-padding-x;
            padding-left: $navbar-nav-link-padding-x;
            display: table;
            &:after {
                content: '';
                height: 2px;
                width: 0;
                background-color: transparent;
                transition: width .4s ease;
                display: table;
                margin: 2px auto 0;
            }
            @include hover {
                &:after {
                    background-color: $yellow;
                    width: 100%;
                }
            }
            &.active {
                &:after {
                  background-color: $yellow;
                  width: 100%;
                }
            }
        }
    }
}

@include media-breakpoint-down('md') {

    body.offcanvas {
        overflow: hidden;

        .navbar-sweeping,
        .navbar-offcanvas {
            transform: translateX(0);
        }

        .navbar-offcanvas {
            .content {

                ul {

                    li {
                        transform: translateY(0);
                        opacity: 1;
                    }
                }
            }
        }
    }
}

/* Header */
header {

    .featured {
       background: linear-gradient(to right, $blue 50%, $white 50%);
       border-top: 1px solid $blue-light;
       border-bottom: 10px solid $yellow;

        .text {
            padding: 0 5rem 0 0;

            h2,
            p {
                opacity: 0;
                transition: transform .8s ease, opacity .8s ease;
                
                &.in-view {
                    transform: rotateY(0);
                    opacity: 1;
                }
            }

            h2 {
                color: $white;
                transform: translateY(50%);
            }

            p {
                color: $white;
                font-size: $font-size-lg;
                font-weight: $font-weight-light;
                margin: 0;
                transform: translateY(75%);
            }
        }
    }

    @include media-breakpoint-down('md') {

        .featured {

            .text {
                padding: 0 2.5rem 0 0;

                h2 {
                    font-size: 2.5rem;
                }

                p {
                    font-size: 1.1rem;
                }
            }
        }
    }

    @include media-breakpoint-down('sm') {

        .featured {
            background: $white;

            .col-md-6 {

                &.col-md-6:first-child {
                    background-color: $blue;
                }
            }

            .text {
                padding: 2rem 0;
            }

            .img {
                text-align: center;
            }
        }
    }
}

.goto {
    padding: 2.5rem 0 0;
    text-align: center;
    .btn {
        background: transparent;
        box-shadow: none;
    }
}


/* Who */
.who {
    background-color: $pink;
    padding: 3.75rem 0 0;
    margin: 2.5rem 0 0;
    position: relative;

    h3,
    .text {
        opacity: 0;
        transition: transform .8s ease, opacity .8s ease;

        &.in-view {
            transform: rotateY(0);
            opacity: 1;
        }
    }

    h3 {
        text-transform: uppercase;
        transform: translateY(50%);
    }

    .title {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;

        h3 {
            margin-top: 8.75rem;
            padding-bottom: 0.625rem;
            border-bottom: 2px solid $white;
        }

        .bg {
            margin-top: 4.375rem;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .text {
        padding: 2rem;
        background-color: $white;
        border-top: 10px solid $blue;
        transform: translateY(25%);

        p {
            font-size: $font-size-lg;
            text-align: justify;
        } 
    }

    @include media-breakpoint-down('md') {

        h3 {
            margin: 0 0 2.5rem;
        }

        .title {

            h3 {
                display: none;
            }

            .bg {
                margin-top: 14rem;
            }
        }
    }

    @include media-breakpoint-down('sm') {

        h3 {
            margin: 0 0 2rem;
        }

        .text {
            padding: 1.5rem;

            p {
                font-size: 1.1rem;
            } 
        }
    }
}

/* Partners */
.partners {
    padding: 3.75rem 0 0;

    .title {
        background: linear-gradient(to right, $yellow 50%, $white 50%);
        margin-bottom: 1rem;

        .content {
            background-color: $yellow;

            > div {
                background-color: $white;
                padding-left: 1.25rem;

                h3 {
                    line-height: 1;
                    text-transform: uppercase;
                    margin: 0;
                    transform: translateY(50%);
                    opacity: 0;
                    transition: transform .8s ease, opacity .8s ease;

                    &.in-view {
                        transform: rotateY(0);
                        opacity: 1;
                    }

                    span {
                        font-size: 1.5rem;
                        letter-spacing: 5px;
                        display: block;
                    }
                }
            }
        } 
    }

    .list {
        background-color: $pink;
        padding: 2rem 0 3.75rem;

        &.in-view {

            ul {
                li {
                    transform: rotateY(0);
                    opacity: 1;
                }
            }
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
            li {
                padding: .25rem;
                flex-basis: 16.66666%;
                transform: translateY(25%);
                opacity: 0;
                transition: transform .8s ease, opacity .8s ease;
                
                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100px;
                    padding: .5rem;
                    background-color: $white;
                }

                &:first-child {
                    transition-delay: .1s;
                }
                &:nth-child(2) {
                    transition-delay: .15s;
                }
                &:nth-child(3) {
                    transition-delay: .2s;
                }
                &:nth-child(4) {
                    transition-delay: .25s;
                }
                &:nth-child(5) {
                    transition-delay: .3s;
                }
                &:nth-child(6) {
                    transition-delay: .35s;
                }
                &:nth-child(7) {
                    transition-delay: .4s;
                }
                &:nth-child(8) {
                    transition-delay: .45s;
                }
                &:nth-child(9) {
                    transition-delay: .5s;
                }
                &:nth-child(10) {
                    transition-delay: .55s;
                }
                &:nth-child(11) {
                    transition-delay: .6s;
                }
                &:nth-child(12) {
                    transition-delay: .65s;
                }
                &:nth-child(13) {
                    transition-delay: .7s;
                }
                &:nth-child(14) {
                    transition-delay: .75s;
                }
                &:nth-child(15) {
                    transition-delay: .8s;
                }
                &:nth-child(16) {
                    transition-delay: .85s;
                }
                &:nth-child(17) {
                    transition-delay: .9s;
                }
                &:nth-child(18) {
                    transition-delay: .95s;
                }
                &:nth-child(19) {
                    transition-delay: 1s;
                }
                &:nth-child(20) {
                    transition-delay: 1.05s;
                }
                &:nth-child(21) {
                    transition-delay: 1.1s;
                }
                &:nth-child(22) {
                    transition-delay: 1.15s;
                }
                &:nth-child(23) {
                    transition-delay: 1.2s;
                }
                &:nth-child(24) {
                    transition-delay: 1.25s;
                }
                &:nth-child(25) {
                    transition-delay: 1.3s;
                }
                &:nth-child(26) {
                    transition-delay: 1.35s;
                }
                &:nth-child(27) {
                    transition-delay: 1.4s;
                }
                &:nth-child(28) {
                    transition-delay: 1.45s;
                }
                &:nth-child(29) {
                    transition-delay: 1.5s;
                }
                &:nth-child(30) {
                    transition-delay: 1.55s;
                }
            }
        }
    }

    @include media-breakpoint-down('sm') {

        .title {

            .content {

                > div {

                    h3 {

                        span {
                            font-size: 1.25rem;
                            letter-spacing: 6px;
                        }
                    }
                }
            } 
        }

        .list {

            ul {
                li {
                    flex-basis: 25%;

                    &:nth-child(29) {
                        margin-left: 25%;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down('xs') {

        .list {

            ul {
                li {
                    flex-basis: 33.33333%;

                    &:nth-child(29) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

/* Stages */
.stages {
    padding: 3.75rem 0;

    h3,
    .item {
        opacity: 0;
        transition: transform .8s ease, opacity .8s ease;

        &.in-view {
            transform: rotateY(0);
            opacity: 1;
        }
    }

    h3 {
        text-align: center;
        text-transform: uppercase;
        margin: 0 0 2.5rem;
        transform: translateY(50%);
    }

    .row {
        + .row {
            margin-top: 30px;
        }
    }

    .item {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        transform: translateY(25%);
        
        .name {
            position: absolute;
            left: 0;
            top: 0;
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 1rem 1rem 1rem 2rem;
            transition: width .4s ease;

            .number {
                position: absolute;
                left: 2rem;
                bottom: 1rem;
                color: $blue-dark;
                font-weight: $font-weight-semi;
                text-align: center;

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 16px;
                    height: 1px;
                    background-color: $blue-dark;
                    display: block;
                }
            }

            > div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                span {
                    &:first-child {
                        color: $blue-dark;
                        font-size: 1rem;
                        font-weight: $font-weight-semi;
                        text-transform: uppercase;
                    }
                    &:last-child {
                        color: $white;
                        font-size: 1.5rem;
                    }
                }
            }
        }

        .img {
            flex-basis: 50%;

            .img-sm {
                display: none;
            }
        }

        &:hover {

            .name {
                width: 100%;
            }
        }

        &.item-a {
            transition-delay: .1s;

            .name {
                background-color: $yellow;
            }
        }

        &.item-b {
            transition-delay: .3s;

            .name {
                background-color: $yellow-dark;
            }
        }

        &.item-c {
            transition-delay: .4s;

            .name {
                background-color: $yellow-darker;
            }
        }

        &.item-d {
            transition-delay: .5s;

            .name {
                background-color: $green-light;
            }
        }

        &.item-e {
            transition-delay: .6s;

            .name {
                 background-color: $green;
            }
        }
    }
    
    @include media-breakpoint-down('md') {

        .item {

            .name {
                width: 60%;
                padding: .5rem .5rem .5rem 1rem;

                .number {
                    bottom: .5rem;
                    left: 1rem;
                }

                > div {

                    span {

                        &:first-child {
                            font-size: $font-size-base;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down('sm') {

        .col-md-6 {
            + .col-md-6 {
                margin-top: 30px;
            }
        }

        .item {

            .name {
                width: 50%;
            }

            .img {

                .img-lg {
                    display: none;
                }

                .img-sm {
                    display: block;
                }
            }
        }
    }

    @include media-breakpoint-down('xs') {

        .item {

            .name {
                width: 70%;
            }
        }
    }
}

/* Process */
.process {
    padding: 3.75rem 0;
    background-color: $body-color;

    h3 {
        color: $white;
        text-align: center;
        margin: 0 0 2.5rem;
        transform: translateY(50%);
        opacity: 0;
        transition: transform .8s ease, opacity .8s ease;

        &.in-view {
            transform: rotateY(0);
            opacity: 1;
        }

    }

    .steps {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: center;
        max-width: 860px;
        margin: 0 auto;
        
        .item {
            padding: 0 1.5rem;
            flex-basis: 33.33333%;
            text-align: center;
            transform: translateY(25%);
            opacity: 0;
            transition: transform .8s ease, opacity .8s ease;

            .img {
                position: relative; 

                &:before {
                    content: '';
                    position: absolute;
                    right: calc(-1.5rem - 12px); 
                    top: 50%;
                    margin-top: -22px;
                    width: 24px;
                    height: 44px;
                    background-image: url(../img/process/arrow-left.png);
                    background-position: left top;
                    background-repeat: no-repeat;
                    display: none;
                }
            }

            .text {
                color: $white;
                margin-top: 1rem;

                span {
                    font-weight: $font-weight-bold;
                    display: block;
                }
            }

            &:first-child {
                transition-delay: .1s;
            }

            &:nth-child(2) {
                transition-delay: .2s;
            }

            &:nth-child(3) {
                transition-delay: .3s;
            }

            &:nth-child(4) {
                transition-delay: .4s;
            }

            &:nth-child(5) {
                transition-delay: .5s;
            }

            &:nth-child(6) {
                transition-delay: .6s;
            }

            &:nth-child(7) {
                transition-delay: .7s;
            }

            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7) {
                margin-top: 3rem;
            }
            
            &:first-child,
            &:nth-child(2),
            &:nth-child(4),
            &:nth-child(5) {
                
                .img {
                    &:before {
                        display: block;
                    }
                }
            }
        }

        &.in-view {

            .item {
                transform: rotateY(0);
                opacity: 1;
            }
        }
    }

    @include media-breakpoint-down('sm') {

        .steps {
            
            .item {
                flex-basis: 50%;

                &:nth-child(3) {
                    margin-top: 3rem;
                }
                
                &:nth-child(2),
                &:nth-child(4) {
                    
                    .img {
                        &:before {
                            display: none;
                        }
                    }
                }

                &:nth-child(3) {

                    .img {
                        &:before {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down('xs') {

        .steps {
            
            .item {
                flex-basis: 100%;
                padding: 0;

                + .item {
                    margin-top: 5rem;
                }

                .img {

                    &:before {
                        top: calc(-2.5rem - 12px); 
                        left: 50%;
                        right: auto;
                        margin-left: -22px;
                        margin-top: 0;
                        width: 44px;
                        height: 24px;
                        background-image: url(../img/process/arrow-down.png);
                        display: block;
                    }
                }

                
                &:nth-child(2),
                &:nth-child(4) {
                    
                    .img {
                        &:before {
                            display: block;
                        }
                    }
                }

                &:first-child {
                    
                    .img {
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }

}

/* Contact */
.contact {
    background-color: $yellow;
    min-height: calc(100vh - 92px);
    position: relative;

    .title {
        background-color: $white;
        padding: 3.75rem 0 2.5rem;
    }

    h3,
    h4 {
        opacity: 0;
        transition: transform .8s ease, opacity .8s ease;

        &.in-view {
            transform: rotateY(0);
            opacity: 1;
        }

    }

    h3 {
        text-transform: uppercase;
        margin: 0;
        transform: translateY(50%);
    }

    h4 {
        display: flex;
        align-items: center;
        height: 62px;
        margin-bottom: 1rem;
        transform: translateY(25%);

        .name {
            color: $black;
            font-size: 1rem;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            padding-left: 1rem;
        }
    }

    .mail,
    form {
        opacity: 0;
        transition: opacity .8s ease;
        transition-delay: .1s;

        &.in-view {
            opacity: 1;
        }
    }

    .content {
        padding: 2rem 0 5.75rem;
        color: $black;
        font-size: $font-size-lg;
        font-weight: $font-weight-light;

    }

    .bottom {
        background-color: $white;
        height: 3.75rem;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

    }

    .col-form-label {
        padding-top: calc(0.25rem + 1px);
        padding-bottom: calc(0.25rem + 1px);
        font-weight: $font-weight-light;
    }

    .form-control {
        font-size: $font-size-lg;
        font-weight: $font-weight-light;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        &input {
            height: 2.5rem;
        }
    }

    .form-group {
        span.error {
            color: $red;
            font-size: $font-size-sm;
            font-weight: $font-weight-bold;
            display: block;
            margin-top: .25rem;
        }
    }

    .btn {
        color: $blue-dark;
        font-size: $font-size-lg;
        text-transform: uppercase;
        padding: .5rem 2rem;
        border-radius: 40px;
    }

    .form-button {
        .loading {
            background: url(../img/contact/loader-form.gif) right center no-repeat;
            height: 48px;
        }
    }

    .thanks {
        color: $white;
        text-align: center;
        margin-top: 4.875rem;
        padding: 0 6rem;
    }

    @include media-breakpoint-down('md') {

        .thanks {
            padding: 0 3rem; 
        }
    }

    @include media-breakpoint-down('sm') {

        .col-md-7 {
            margin-top: 30px;
        }

        .col-form-label {
            padding-top: 0;
            padding-bottom: 0.5rem;
            font-size: 1.1rem;
        }

        .form-control {
            font-size: $font-size-base;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }

        .thanks {
            text-align: left;
            margin-top: 0;
            padding: 0;
        }
    }
}